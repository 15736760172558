export const OPTED_IN = 'Opted-In'
export const OPTED_OUT = 'Opted-Out'
export const NO_PREFERENCE = 'No Preference'

export const TIMELINE_PREFERENCE_WIDGET_SUBMISSIONS = 0
export const TIMELINE_EMAIL_LINK_SUBMISSION = 1
export const TIMELINE_COOKIE_SUBMISSION = 2
export const TIMELINE_API = 3
export const TIMELINE_CS_PORTAL_SUBMISSION = 4
export const TIMELINE_DATA_UPLOAD = 5
export const TIMELINE_LINK_SUBMISSION = 6
export const TIMELINE_PUBLIC_PORTAL_SUBMISSION = 7
export const TIMELINE_CS_PORTALNEW_SUBMISSION = 8
export const TIMELINE_LOADER = 10

export const timelineLabels = {
	[TIMELINE_COOKIE_SUBMISSION]: 'Cookie Submission',
	[TIMELINE_CS_PORTAL_SUBMISSION]: 'CS Portal Submission',
	[TIMELINE_CS_PORTALNEW_SUBMISSION]: 'CS Portal Submission',
	[TIMELINE_PUBLIC_PORTAL_SUBMISSION]: 'Public Portal Submission',
	[TIMELINE_PREFERENCE_WIDGET_SUBMISSIONS]: 'Preference Widget Submission',
	[TIMELINE_LINK_SUBMISSION]: 'Link Submission',
	[TIMELINE_EMAIL_LINK_SUBMISSION]: 'Email Link Submission',
	[TIMELINE_API]: 'API',
	[TIMELINE_DATA_UPLOAD]: 'Data Upload',
	[TIMELINE_LOADER]: 'Loader'
}

export const timelineColors = {
	[TIMELINE_COOKIE_SUBMISSION]: 'red lighten-1',
	[TIMELINE_CS_PORTAL_SUBMISSION]: 'blue lighten-1',
	[TIMELINE_CS_PORTALNEW_SUBMISSION]: 'blue lighten-1',
	[TIMELINE_PUBLIC_PORTAL_SUBMISSION]: 'orange darken-1',
	[TIMELINE_PREFERENCE_WIDGET_SUBMISSIONS]: 'green darken-1',
	[TIMELINE_LINK_SUBMISSION]: 'blue-grey lighten-1',
	[TIMELINE_EMAIL_LINK_SUBMISSION]: 'blue-grey lighten-1',
	[TIMELINE_API]: 'blue darken-1',
	[TIMELINE_DATA_UPLOAD]: 'blue-grey lighten-1',
	[TIMELINE_LOADER]: 'blue-grey lighten-1'
}

export const timelineIcons = {
	[TIMELINE_COOKIE_SUBMISSION]: 'mdi-cookie',
	[TIMELINE_CS_PORTAL_SUBMISSION]: 'mdi-headset',
	[TIMELINE_CS_PORTALNEW_SUBMISSION]: 'mdi-headset',
	[TIMELINE_PUBLIC_PORTAL_SUBMISSION]: 'mdi-face',
	[TIMELINE_PREFERENCE_WIDGET_SUBMISSIONS]: 'mdi-laptop',
	[TIMELINE_LINK_SUBMISSION]: 'mdi-link',
	[TIMELINE_EMAIL_LINK_SUBMISSION]: 'mdi-link',
	[TIMELINE_API]: 'mdi-api',
	[TIMELINE_DATA_UPLOAD]: 'mdi-briefcase-upload',
	[TIMELINE_LOADER]: 'mdi-briefcase-upload'
}
