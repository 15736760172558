import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '../../utils/date-formatting.js'
import { transactionalApi } from '../http-client.js'
import { csPortalPrivacyPolicies, updatePrivacyPolicies } from '../../state/privacy-policies.js'
import {
	groupedContactDetailsFields
} from '../../../shared/state/configuration.js'

const UNIVERSAL_BRAND_ID = 0

export const getDataSubject = (id, brandId) => {
	return transactionalApi.get(`/Direct/GetDataSubjectCurrent/${id}?brandId=${brandId}`).then(({ data }) => JSON.parse(JSON.stringify({
		data: {
			...data,
			currentStandDak: {
				...data.currentStandDak,
				syrenisId: data.currentStandDak.syrenisID,
				syrenisID: undefined
			},
			currentPreferences: data.currentPreferences.map(preference => ({
				...preference,
				isOptIn: Boolean(preference.PreferenceValue),
				isChannelOptedIn: preference.isChannelOptedIn,
				dateSubmitted: preference.dateSubmitted,
				formattedDateSubmitted: format(new Date(preference.dateSubmitted), DATE_TIME_FORMAT),
				extendedPrefs: [...new Set(preference.extendedPreferences.map(extendedPreference => extendedPreference.key))].map(key => {
					return {
						key,
						value: preference.extendedPreferences.filter(extPref => extPref.key === key).map(extPref => extPref.value).toString()
					}
				}),
				channelId: preference.channelID,
				channelID: undefined,
				privacyId: preference.privacyID,
				privacyID: undefined
			})),
			futurePreferences: data.futurePreferences.map(preference => ({
				...preference,
				formattedDateSubmitted: format(new Date(preference.dateSubmitted), DATE_TIME_FORMAT),
				isOptIn: Boolean(preference.PreferenceValue),
				extendedPrefs: [...new Set(preference.extendedPreferences?.map(extendedPreference => extendedPreference.key))].map(key => {
					return {
						key,
						value: preference.extendedPreferences.filter(extPref => extPref.key === key).map(extPref => extPref.value).toString()
					}
				}),
				channelId: preference.channelID,
				channelID: undefined,
				privacyId: preference.privacyID,
				privacyID: undefined
			}))
		}
	})))
}

export const postDataSubjectPreferenceChanges = ({
	sendConfirmationEmail,
	selectedHeaderFooterCombinationId, // not used - yet!
	selectedResponseChannelId,
	sourceCode,
	dataSubject,
	preferences
}) => {
	const mappedPreferences = preferences.map(preference => ({
		...preference,
		sourceCode,
		selectedResponseChannelID: selectedResponseChannelId
	}))
	if (!sendConfirmationEmail) return postDataSubject({ dataSubject, preferences: mappedPreferences })
	const { syrenisId } = dataSubject
	return Promise.all([
		postDataSubject({ dataSubject: { syrenisId }, preferences: mappedPreferences }),
		transactionalApi.get(`/api/DataSubjects/SendConfirmationEmail/${syrenisId}`)
	])
}

export const getPostcodeData = postcode => {
	return transactionalApi.instance({
		method: 'get',
		baseURL: `https://api.getAddress.io/find/${postcode}?api-key=I2aBIH6xCEqGh0sEzetVgg8746&expand=true&sort=true`
	}).then(({ data: { addresses, postcode } }) => ({
		// eslint-disable-next-line camelcase
		data: addresses.map(({ line_1, line_2, line_3, line_4, town_or_city, county, country }) => ({
			address1: line_1,
			address2: line_2,
			address3: line_3,
			address4: line_4,
			town: town_or_city,
			county,
			country,
			postcode
		}))
	}))
}

export const postDataSubject = ({ dataSubject, preferences }) => {
	const MULTI_SELECT_FIELD_TYPE_ID = 3
	const mappedPreferences = preferences
		? Object.keys(preferences).map(channelId => ({
			channelId: preferences[channelId].channelId,
			selectedResponseChannelId: preferences[channelId].selectedResponseChannelID ? preferences[channelId].selectedResponseChannelID : 0,
			preferenceValue: preferences[channelId].preferenceValue,
			privacyPolicyId: preferences[channelId].privacyId,
			amendDateFrom: preferences[channelId].amendDateFrom || false,
			validFrom: preferences[channelId].validFrom,
			validTo: preferences[channelId].validTo,
			sourceCode: preferences[channelId].sourceCode,
			extendedPrefs: preferences[channelId].extendedPrefs && preferences[channelId].extendedPrefs.length >= 1
				? [...preferences[channelId].extendedPrefs].map(extendedPref => ({
					keyId: extendedPref.keyId,
					value: extendedPref.fieldTypeId === MULTI_SELECT_FIELD_TYPE_ID && extendedPref.value
						? extendedPref.value.split(',').join('|')
						: extendedPref.value
				}))
				: []
		}))
		: undefined
	if (mappedPreferences) {
		Object.keys(mappedPreferences).forEach(preference => {
			const privacyPolicy = mappedPreferences[preference].privacyPolicyId
			const responseChannel = mappedPreferences[preference].selectedResponseChannelId
			const sourceCode = mappedPreferences[preference].sourceCode
			if (privacyPolicy === 0 || privacyPolicy === undefined || privacyPolicy === null) delete mappedPreferences[preference].privacyPolicyId
			if (responseChannel === 0 || responseChannel === undefined || responseChannel === null) delete mappedPreferences[preference].selectedResponseChannelId
			if (sourceCode === 0 || sourceCode === undefined || sourceCode === null) delete mappedPreferences[preference].sourceCode
		})
	}
	const mappedDataSubject = {}
	dataSubject.logTypeId = 8
	Object.keys(dataSubject).forEach(key => {
		if (dataSubject[key] !== '') mappedDataSubject[key] = dataSubject[key]
	})
	mappedDataSubject.organisation = mappedDataSubject.organisationName
	delete mappedDataSubject.organisationName
	return transactionalApi.post('/Direct/Update', {
		...mappedDataSubject,
		preferences: mappedPreferences
	})
}
export const getDataSubjectSubmissionString = submissionId => {
	return transactionalApi.get(`/api/DataSubjects/GetDataSubjectSubmissionString?submissionId=${submissionId}`).then(res => res.data.submissionString)
}

export const getDataSubjectSubmissions = syrenisId => {
	updatePrivacyPolicies()
	const contactFields = groupedContactDetailsFields.value
	const fieldGroups = Object.keys(contactFields).map(fieldGroupName => ({
		fields: contactFields[fieldGroupName]
	}))
	const fields = fieldGroups.map(a => a.fields).flat()
	return transactionalApi.post('/v3/oquery/GetDataSubjectSubmissions', {
		identifiers: [
			{
				identifierId: 1,
				value: syrenisId.toString()
			}
		]
	}).then(({ data }) => {
		const flattenedSubmissions = []
		const flattenSubmissions = submissions => {
			if (!Array.isArray(submissions)) {
				return
			}
			submissions.forEach(sub => {
				flattenedSubmissions.push({
					syrenisId: sub.SyrenisId,
					contactDetails: sub.ContactDetails,
					submissions: sub.Submissions
				})
				if (sub.MergedRecords) {
					flattenSubmissions(sub.MergedRecords)
				}
			})
		}
		flattenSubmissions([data])
		data = { submissions: [] }
		flattenedSubmissions.forEach(ds => {
			data.submissions.push(
				ds?.submissions?.map(submission => {
					const globalDefaultPolicy = csPortalPrivacyPolicies(UNIVERSAL_BRAND_ID).find(({ isGlobalDefault }) => isGlobalDefault)
					return {
						syrenisId: ds.syrenisId,
						contactDetails: ds.contactDetails,
						submissionId: submission.SubmissionId,
						submissionSource: submission.SubmissionSource,
						submissionLogType: submission.SubmissionLogType,
						merged: submission.Merged,
						dateSubmitted: submission.DateSubmitted,
						formattedDateSubmitted: format(new Date(submission.DateSubmitted), DATE_TIME_FORMAT),
						submittedContactDetails: submission.SubmittedContactDetails.map(contactDetail => ({
							fieldName: fields.filter(e => e.fieldName.toLowerCase() === contactDetail.FieldName.toLowerCase()).length > 0
								? fields.filter(e => e.fieldName.toLowerCase() === contactDetail.FieldName.toLowerCase())[0].fieldLabel
								: contactDetail.FieldName,
							previousValue: contactDetail.PreviousValue ? contactDetail.PreviousValue : 'N/A',
							newValue: contactDetail.NewValue === contactDetail.PreviousValue
								? 'No-Change'
								: contactDetail.FieldName === 'DateAdded'
									? format(new Date(contactDetail.NewValue), DATE_TIME_FORMAT)
									: contactDetail.NewValue
						})),
						submittedPreferences: submission.SubmittedPreferences.map(submittedPreference => ({
							channelName: submittedPreference.ChannelName,
							statementText: submittedPreference.StatementText,
							brandName: submittedPreference.BrandName,
							sourceCode: submittedPreference.SourceCode,
							privacyPolicyId: submittedPreference.PrivacyPolicyId,
							privacyPolicyName: submittedPreference.PrivacyPolicyName || globalDefaultPolicy?.text,
							privacyPolicyUrl: submittedPreference.PrivacyPolicyUrl,
							responseChannel: submittedPreference.ResponseChannel,
							previousValue: submittedPreference.PreviousValue === 0
								? 'Opted-Out'
								: submittedPreference.PreviousValue === 1
									? 'Opted-In'
									: 'N/A',
							newValue: submittedPreference.NewValue === 0
								? 'Opted-Out'
								: 'Opted-In',
							validFrom: submittedPreference.ValidFrom
								? format(new Date(submittedPreference.ValidFrom), DATE_TIME_FORMAT)
								: format(new Date(submittedPreference.ConsentDate), DATE_TIME_FORMAT),
							validTo: submittedPreference.ValidTo
								? format(new Date(submittedPreference.ValidTo), DATE_TIME_FORMAT)
								: null
						}))
					}
				})
			)
		})
		return data
	})
}

export const getDataSubjectHistory = syrenisId => {
	return transactionalApi.post('/v3/oquery/GetDataSubjectHistory', {
		identifiers: [
			{
				identifierId: 1,
				value: syrenisId.toString()
			}
		]
	}).then(({ data }) => ({
		data: {
			preferences: [
				...data.CurrentPreferences.map(pref => ({
					brandName: pref.BrandName,
					brandId: pref.BrandID,
					channelName: pref.ChannelName,
					channelId: pref.ChannelID,
					parentChannelName: pref.ParentChannelName,
					parentChannelId: pref.ParentChannelID,
					dateSubmitted: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					dateSubmittedUtc: pref.DateSubmittedUTC,
					preferenceValue: pref.IsChannelOptedIn === 1 ? 'Opt-In' : 'Opt-Out',
					submissionId: pref.SubmissionID,
					isCurrent: true,
					divId: pref.DivID,
					sourceCode: pref.SourceCode,
					responseChannel: pref.ResponseChannel,
					privacyPolicyName: pref.PrivacyPolicyName,
					privacyPolicyUrl: pref.PrivacyPolicyURL,
					validFrom: pref.PreferenceValidFrom
						? format(new Date(pref.PreferenceValidFrom), DATE_TIME_FORMAT)
						: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					validTo: pref.PreferenceValidTo ? format(new Date(pref.PreferenceValidTo), DATE_TIME_FORMAT) : '',
					statementText: pref.StatementText,
					headerText: pref.HeaderText,
					footerText: pref.FootnoteText,
					extendedPreferences: pref.ExtendedPrefs.length >= 1
						?	pref.ExtendedPrefs?.map(extendedPref => ({
							key: extendedPref.Key,
							value: extendedPref.Value
						})).filter(({ value }) => value)
						: [],
					preferenceId: pref.PreferenceID
				})),
				...(data.HistoricPreferences || []).map(pref => ({
					brandName: pref.BrandName,
					brandId: pref.BrandID,
					channelName: pref.ChannelName,
					channelId: pref.ChannelID,
					parentChannelName: pref.ParentChannelName,
					parentChannelId: pref.ParentChannelID,
					dateSubmitted: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					dateSubmittedUtc: pref.DateSubmittedUTC,
					preferenceValue: pref.IsChannelOptedIn === 1 ? 'Opt-In' : 'Opt-Out',
					submissionId: pref.SubmissionID,
					isCurrent: false,
					divId: pref.DivID,
					sourceCode: pref.SourceCode,
					responseChannel: pref.ResponseChannel,
					privacyPolicyName: pref.PrivacyPolicyName,
					PrivacyPolicyURL: pref.PrivacyPolicyURL,
					validFrom: pref.PreferenceValidFrom
						? format(new Date(pref.PreferenceValidFrom), DATE_TIME_FORMAT)
						: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					validTo: pref.PreferenceValidTo ? format(new Date(pref.PreferenceValidTo), DATE_TIME_FORMAT) : '',
					statementText: pref.StatementText,
					headerText: pref.HeaderText,
					footerText: pref.FootnoteText,
					extendedPreferences: pref.ExtendedPrefs.length >= 1
						?	pref.ExtendedPrefs?.map(extendedPref => ({
							key: extendedPref.Key,
							value: extendedPref.Value
						})).filter(({ value }) => value)
						: [],
					preferenceId: pref.PreferenceID
				}))
			],
			mergedRecords: data?.MergedRecords?.map(mergedRecord => ({
				mergedSyrenisId: mergedRecord.MergedSyrenisID,
				mergeDate: mergedRecord.MergeDate,
				mergedStandDaks: mergedRecord.MergedStandDaks?.map(mergedStandDak => ({
					syrenisId: mergedStandDak.SyrenisID,
					title: mergedStandDak.Title,
					foreName: mergedStandDak.ForeName,
					middleName: mergedStandDak.MiddleName,
					surname: mergedStandDak.Surname,
					address1: mergedStandDak.Address1,
					address2: mergedStandDak.Address2,
					address3: mergedStandDak.Address3,
					address4: mergedStandDak.Address4,
					address5: mergedStandDak.Address5,
					town: mergedStandDak.Town,
					county: mergedStandDak.County,
					postcode: mergedStandDak.Postcode,
					country: mergedStandDak.Country,
					email1: mergedStandDak.Email1,
					email2: mergedStandDak.Email2,
					telephone1: mergedStandDak.Telephone1,
					telephone2: mergedStandDak.Telephone2,
					mobile: mergedStandDak.Mobile,
					organisationName: mergedStandDak.OrganisationName,
					jobTitle: mergedStandDak.JobTitle,
					urn1: mergedStandDak.URN1,
					urn2: mergedStandDak.URN2,
					urn3: mergedStandDak.URN3,
					custom1: mergedStandDak.Custom1,
					custom2: mergedStandDak.Custom2,
					custom3: mergedStandDak.Custom3,
					custom4: mergedStandDak.Custom4,
					custom5: mergedStandDak.Custom5,
					custom6: mergedStandDak.Custom6,
					custom7: mergedStandDak.Custom7,
					custom8: mergedStandDak.Custom8,
					custom9: mergedStandDak.Custom9,
					custom10: mergedStandDak.Custom10,
					dateAdded: mergedStandDak.DateAdded,
					dateUpdated: mergedStandDak.DateUpdated,
					submission: {
						submissionId: mergedStandDak.Submission.SubmissionID,
						logType: mergedStandDak.Submission.LogType,
						logTypeDescription: mergedStandDak.Submission.LogTypeDescription,
						submissionDateTimeUTC: mergedStandDak.Submission.SubmissionDateTimeUTC
					}
				})) ?? [],
				mergedPreferences: mergedRecord.MergedPreferences?.map(pref => ({
					brandName: pref.BrandName,
					brandId: pref.BrandID,
					channelName: pref.ChannelName,
					channelId: pref.ChannelID,
					parentChannelName: pref.ParentChannelName,
					parentChannelId: pref.ParentChannelID,
					dateSubmitted: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					dateSubmittedUtc: pref.DateSubmittedUTC,
					preferenceValue: pref.IsChannelOptedIn === 1 ? 'Opt-In' : 'Opt-Out',
					submissionId: pref.SubmissionID,
					isCurrent: false,
					divId: pref.DivID,
					sourceCode: pref.SourceCode,
					responseChannel: pref.ResponseChannel,
					privacyPolicyName: pref.PrivacyPolicyName,
					PrivacyPolicyURL: pref.PrivacyPolicyURL,
					validFrom: pref.PreferenceValidFrom
						? format(new Date(pref.PreferenceValidFrom), DATE_TIME_FORMAT)
						: format(new Date(pref.DateSubmitted), DATE_TIME_FORMAT),
					validTo: pref.PreferenceValidTo ? format(new Date(pref.PreferenceValidTo), DATE_TIME_FORMAT) : '',
					statementText: pref.StatementText,
					headerText: pref.HeaderText,
					footerText: pref.FootnoteText,
					extendedPreferences: pref.ExtendedPrefs.length >= 1
						?	pref.ExtendedPrefs?.map(extendedPref => ({
							key: extendedPref.Key,
							value: extendedPref.Value
						})).filter(({ value }) => value)
						: [],
					preferenceId: pref.PreferenceID
				})) ?? []
			})) ?? []
		}
	}))
}

export const getDataSubjectAssociations = (id, brandId) => {
	return transactionalApi.get(`/Direct/GetDataSubjectAssociations/${id}?brandId=${brandId}`).then(({ data }) => JSON.parse(JSON.stringify({
		data: {
			dataCorrectAt: data.dataCorrectAt,
			deviceAssociation: data.deviceAssociation.map(device => ({
				associatedSyrenisId: device.associatedSyrenisID,
				associatedGuid: device.associatedGUID
			}))
		}
	})))
}
