<template>
	<div class="cassie-vertical-md">
		<div
			v-for="fieldGroup in fieldGroups"
			:key="fieldGroup.fieldGroupName"
		>
			<SectionCard
				v-if="fieldsExist(fieldGroup)"
			>
				<template #title>
					{{ fieldGroup.fieldGroupName }}
				</template>
				<template #body>
					<div
						v-if="fieldGroup.fieldGroupName === ADDRESS_DETAILS_GROUP && showPostcodeLookup && !viewDataSubject"
						class="d-flex align-center mb-4"
						style="width: 550px;"
					>
						<TextField
							v-model="postcodeLookup"
							label="Postcode"
						/>
						<v-menu
							v-model="showPostcodeLookupDataSelector"
							min-width="550px"
							max-width="550px"
							max-height="250px"
							content-class="background overflow-auto"
						>
							<template #activator="{ on: { click: showMenu } }">
								<PrimaryActionButton
									class="ml-4"
									@click="findPostcode(() => showMenu($event))"
								>
									<v-icon
										left
										dark
									>
										mdi-magnify
									</v-icon>
									Look Up
								</PrimaryActionButton>
							</template>

							<v-list v-if="postcodeLookupData.length">
								<v-list-item
									v-for="(datum, i) in postcodeLookupData"
									:key="i"
									class="text-no-wrap"
									@click="fillAddress(datum, fieldGroup.fields)"
								>
									{{ getAddressString(datum) }}
								</v-list-item>
							</v-list>
							<div
								v-else
								class="text-center my-4"
							>
								No results
							</div>
						</v-menu>
					</div>
					<v-row dense>
						<v-col
							v-for="field in getFields(fieldGroup)"
							:key="field.fieldName"
							:cols="6"
						>
							<component
								:is="field.fieldType === 2 && !viewDataSubject && !field.isReadOnly ? 'Dropdown' : 'TextField'"
								:value="dataSubject[field.fieldName]"
								:label="getLabel(field)"
								:disabled="editContactDetails && field.isReadOnly || viewDataSubject"
								:items="getDropdownOptions(field)"
								:rules="getValidationRules(field)"
								:clearable="!isMandatory(field) && field.fieldType === 2"
								@input="updateDataSubjectField(field.fieldName, $event)"
							/>
						</v-col>
					</v-row>
				</template>
			</SectionCard>
		</div>
	</div>
</template>

<script>
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { getPostcodeData } from '../../../../../shared/utils/api/data-subject.js'
import {
	ADDRESS_DETAILS_GROUP,
	OTHER_DETAILS_GROUP,
	groupedContactDetailsFields,
	showPostcodeLookup
} from '../../../../../shared/state/configuration.js'
import DataSubjectBackButton from './data-subject/data-subject-back-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'

export default {
	components: { SectionCard, DataSubjectBackButton, PrimaryActionButton, SecondaryActionButton, TextField, Dropdown },
	props: {
		dataSubject: {
			type: Object,
			default: () => {}
		},
		editContactDetails: {
			type: Boolean,
			default: false
		},
		viewDataSubject: {
			type: Boolean,
			default: false
		},
		createDataSubject: {
			type: Boolean,
			default: false
		},
		hideReadOnlyFields: {
			type: Boolean,
			default: false
		},
		requiredFields: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			groupedContactDetailsFields,
			showPostcodeLookup
		}
	},
	data () {
		return {
			ADDRESS_DETAILS_GROUP,
			OTHER_DETAILS_GROUP,
			postcodeLookup: '',
			postcodeLookupData: [],
			showPostcodeLookupDataSelector: false
		}
	},
	computed: {
		fieldGroups () {
			return Object.keys(this.groupedContactDetailsFields).map(fieldGroupName => ({
				fieldGroupName,
				fields: this.groupedContactDetailsFields[fieldGroupName].map(field => ({
					...field,
					clearable: fieldGroupName === OTHER_DETAILS_GROUP
				}))
			})).filter(({ fields }) => fields.length)
		}
	},
	methods: {
		fieldsExist (fieldGroup) {
			if (!this.createDataSubject) {
				return true
			} else {
				const filteredGroups = fieldGroup.fields.filter(({ displayInCreateDataSubjectScreen }) => displayInCreateDataSubjectScreen === true)
				return filteredGroups.length >= 1
			}
		},
		isMandatory ({ isMandatory, isReadOnly, isMandatoryInCreateDataSubjectScreen, fieldName }) {
			return this.requiredFields.includes(fieldName.toLowerCase()) ||
			!this.editContactDetails &&
			isMandatoryInCreateDataSubjectScreen ||
			isMandatory && !isReadOnly && this.editContactDetails
		},
		getDropdownOptions ({ allowedValues = [] }) {
			return allowedValues.map(value => ({ value, text: value }))
		},
		getFields (fieldGroup) {
			if (!this.createDataSubject) {
				return fieldGroup.fields
			} else {
				const filteredFields = fieldGroup.fields.filter(({ displayInCreateDataSubjectScreen }) => displayInCreateDataSubjectScreen === true)
				return filteredFields
			}
		},
		getLabel (field) {
			const asterisk = this.isMandatory(field) && !this.viewDataSubject ? ' *' : ''
			return field.fieldLabel + asterisk
		},
		getValidationRules (field) {
			if (this.viewDataSubject) {
				return {}
			}
			switch (field.fieldType) {
				case 3:
					return {
						required: this.isMandatory(field),
						email: true
					}
				case 4:
					return {
						required: this.isMandatory(field),
						regex: field.allowedValues
					}
				case 5:
					return {
						required: this.isMandatory(field),
						alpha: true
					}
				case 6:
					return {
						required: this.isMandatory(field),
						alpha_num: true
					}
				case 7:
					return {
						required: this.isMandatory(field),
						numeric: true
					}
				case 8:
					return {
						required: this.isMandatory(field),
						decimal: true
					}
				default:
					return {
						required: this.isMandatory(field)
					}
			}
		},
		async findPostcode (showMenu) {
			this.postcodeLookupData = []
			if (!this.postcodeLookup) {
				showMenu()
				return
			}
			const { data: postcodeLookupData } = await getPostcodeData(this.postcodeLookup)
			this.postcodeLookupData = postcodeLookupData
			showMenu()
		},
		fillAddress (addressData, fields) {
			const dataSubject = { ...this.dataSubject }
			Object.keys(addressData).forEach(key => {
				if (fields.find(({ fieldName, isReadOnly }) => fieldName === key && !isReadOnly)) {
					dataSubject[key] = addressData[key]
				}
			})
			this.updateDataSubject(dataSubject)
		},
		getAddressString (addressData) {
			return Object.values(addressData).join(', ')
		},
		updateDataSubjectField (field, value) {
			this.updateDataSubject({
				...this.dataSubject,
				[field]: value
			})
		},
		updateDataSubject (value) {
			this.$emit('update:dataSubject', value)
		}
	}
}
</script>
