<template>
	<SecondaryActionButton @click="$emit('click')">
		<v-icon
			left
			dark
		>
			mdi-chevron-left
		</v-icon>
		Back to search
	</SecondaryActionButton>
</template>

<script>
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
export default {
	name: 'data-subject-back-button',
	components: { SecondaryActionButton }
}
</script>
