<template>
	<validation-observer>
		<TextField
			v-if="fieldTypeId === EXTENDED_PREF_TYPE_TEXT_FIELD"
			:value="value"
			:label="label"
			:disabled="disabled"
			:rules="rules"
			small
			@input="$emit('input', $event)"
		/>
		<Dropdown
			v-else-if="fieldTypeId === EXTENDED_PREF_TYPE_DROPDOWN"
			:value="value"
			:label="label"
			:disabled="disabled"
			:items="availableValuesIfDropdownMapped"
			:rules="rules"
			small
			@input="$emit('input', $event)"
		/>
		<Dropdown
			v-else-if="fieldTypeId === EXTENDED_PREF_TYPE_DROPDOWN_MULTISELECT"
			:value="value ? value.split(',') : []"
			:label="label"
			:disabled="disabled"
			:items="availableValuesIfDropdownMapped"
			:rules="rules"
			small
			multiple
			@input="newValue => $emit('input', newValue.join(','))"
		/>
	</validation-observer>
</template>

<script>
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
const EXTENDED_PREF_TYPE_TEXT_FIELD = 1
const EXTENDED_PREF_TYPE_DROPDOWN = 2
const EXTENDED_PREF_TYPE_DROPDOWN_MULTISELECT = 3

export default {
	components: { TextField, Dropdown },
	props: {
		value: [String, Object, Number],
		disabled: Boolean,
		fieldTypeId: Number,
		label: String,
		availableValuesIfDropdown: {
			type: Array,
			default: () => []
		},
		rules: {
			type: Object,
			default: () => {}
		}
	},
	data () {
		return {
			EXTENDED_PREF_TYPE_TEXT_FIELD,
			EXTENDED_PREF_TYPE_DROPDOWN,
			EXTENDED_PREF_TYPE_DROPDOWN_MULTISELECT
		}
	},
	computed: {
		availableValuesIfDropdownMapped () {
			return this.availableValuesIfDropdown.slice().sort((a, b) => a.displayOrder - b.displayOrder).map(({ value }) => value)
		}
	}
}
</script>
